import {Component} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'darklime-client-app';

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private meta: Meta) {
    this.addMetaTags();
  }

  private addMetaTags() {
    this.meta.addTags([
      {name: 'description', content: 'DarkLime Team! Angular experts. Progressive web app.'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'robots', content: 'INDEX, FOLLOW'},
      {name: 'author', content: 'DarkLime'},
      {name: 'keywords', content: 'TypeScript, Angular 9, 8, 7, Universal, PWA, Progressive web application, software engineers, remote, AWS, Google Cloud, server side rendering problem'},
      {name: 'date', content: '2020-06-02', scheme: 'YYYY-MM-DD'},
      {httpEquiv: 'Content-Type', content: 'text/html'},
      {property: 'og:title', content: "DarkLime Team! Angular experts | PWA | AWS | Google Cloud | Material Design"},
      {property: 'og:type', content: "website"},
      {property: "og:image", content: "https://darklimeteam.com/assets/icon/logo.svg"},
      {charset: 'UTF-8'}
   ]);
  }
}
