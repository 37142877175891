import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Client } from '@app/models/client';
import { Observable } from 'rxjs';


@Injectable()
export class SendEmailService {
  constructor(private http: HttpClient) { }
  
  public sendEmail(client: Client): Observable<any> {
    const url = 'http://localhost:8080/sendemail';
    return this.http.post<any>(url, client);
  }
}