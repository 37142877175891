
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {FlexLayoutServerModule} from '@angular/flex-layout/server';
@NgModule({
  declarations: [

  ],
  imports: [
    HttpClientModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
  ],
  exports: [
    HttpClientModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule
  ],
  providers: [],
})
export class SharedModule { }
