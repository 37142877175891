import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { SendEmailService } from './service/send-email.service';
import { AngularFireModule } from '@angular/fire';

const config = {
  "apiKey": "AIzaSyASf42ITk-oXNTkEWshRF5f0AoNtkqg8Ls",
  "authDomain": "darklime-99e0c.firebaseapp.com",
  "databaseURL": "https://darklime-99e0c.firebaseio.com",
  "messagingSenderId": "822248097484",
  "projectId": "darklime-99e0c"
}
// deploy to cloud functions here https://github.com/angular/angularfire/blob/master/docs/deploy/getting-started.md

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AngularFireModule.initializeApp(config),
    BrowserModule.withServerTransition({ appId: 'app' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule
  ],
  providers: [SendEmailService],
  bootstrap: [AppComponent]
})
export class AppModule { }
